















































































.tooltip.b-tooltip{
  opacity: 1;
  .tooltip-inner{
    background: #000;
  }
}
.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before{
  border-bottom-color: #000;
}
.tooltip-button{
  appearance: none;
  background: none;
  border: 0;
  .tooltip-color{
    border-radius: 50%;
    border: 2px solid #fff;
    width: 25px;
    height: 25px;
    display: block;
  }
}
.tooltip-close-button{
  appearance: none;
  background: none;
  border: 0;
  color: #fff;
  padding: 0;
}
.one-color-stash{
  display: none !important;
}
